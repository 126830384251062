<template>
  <div class="payment-plan-info">
    <table class="payment-plan-info__table">
      <template v-for="item in items">
        <tr v-if="item.label" class="payment-plan-info__table__tr" :key="item.label">
          <td class="payment-plan-info__table__tr__td--label">{{ item.label }}</td>
          <td class="payment-plan-info__table__tr__td--text">{{ item.text }}</td>
        </tr>
      </template>
    </table>
    <p class="payment-plan-info__warning">※ {{!subscription ? 'お支払い開始日' : '次回更新日'}}の前日までに解約されない場合、自動的に更新されます。</p>
  </div>
</template>

<script>
import moment from '@/assets/lib/moment'

export default {
  mixins: [moment],
  computed: {
    /**
     * @return {Object[]} 表示する内容
     */
    items () {
      return [
        { label: 'ステータス', text: '有料会員' + (this.isFreetrial ? '（無料キャンペーン中）' : '' ) },
        this.isFreetrial || this.planPrice <= this.paymentPrice ? {} : { label: 'プラン料金', text: this.planPrice.toLocaleString() + '円' },
        this.isFreetrial || this.planPrice <= this.paymentPrice ? {} : { label: '早期入会特典', text: (this.planPrice - this.paymentPrice).toLocaleString() + '円引き' },
        { label: 'お支払い方法', text: 'クレジットカード決済' },
        this.subscription ? {} : { label: 'お支払い開始日', text: this.formatted(this.billingStartAt, 'YYYY年MM月DD日') },
        !this.subscription ? {} : { label: '次回更新日', text: this.formatted(this.nextPaymentAt, 'YYYY年MM月DD日') },
        { label: '次回お支払い額', text: this.paymentPrice.toLocaleString() + '円' }
      ]
    },
    /**
     * @return {Object} サブスクリプション情報
     */
    subscription () {
      return this.$store.getters['subscriptions/subscription']
    },
    /**
     * @return {Object} サブスクリプションスケジュール
     */
    subscriptionSchedule () {
      return this.$store.getters['subscriptionSchedule/subscriptionSchedule']
    },
    /**
     * @return {Date} 支払開始日
     */
    billingStartAt () {
      return this.subscriptionSchedule ? this.subscriptionSchedule.startAt.toDate() : null
    },
    /**
     * @return {Boolean} 無料トライアル中か
     */
    isFreetrial () {
      return this.subscription && this.subscription.status === 'trialing'
    },
    /**
     * @return {Date} 次回支払い年月日
     */
    nextPaymentAt () {
      return this.subscription.nextPaymentAt.toDate()
    },
    /**
     * @return {String} 表示金額
     */
    planPrice () {
      return Number(process.env.VUE_APP_PRICE)
    },
    /**
     * @return {Object} 登録中のプラン情報
     */
    plan () {
      return this.$store.getters['plans/plan']
    },
    /**
     * @return {Number} 現在の支払い金額
     */
    paymentPrice () {
      return this.plan.amount
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.payment-plan-info {
  padding: 0 calc(#{$unit_size} * 5 - #{$padding_width});
  &__table {
    width: 100%;
    &__tr {
      display: block;
      margin-bottom: $unit_size*4;
      &:last-child {
        margin: 0;
      }
      &__td {
        font-size: 1.2rem;
        &--label {
          @extend .payment-plan-info__table__tr__td;
          width: 100px;
          color: $gray_darken_color;
        }
        &--text {
          @extend .payment-plan-info__table__tr__td;
        }
      }
    }
  }
  &__warning {
    margin: $unit_size*5 0 0;
    font-size: 1.2rem;
    color: $gray_darken_color;
  }
}
</style>
