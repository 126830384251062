<template>
  <div class="payment-plan" v-if="!isProcessing">
    <payment-plan-info />
  </div>
</template>

<script>
import PaymentPlanInfo from '@/components/payment/plan/info'

export default {
  components: { PaymentPlanInfo },
  name: 'payment_plan',
  async mounted () {
    if (!this.subscription && !this.subscriptionSchedule) await this.$store.dispatch('subscriptionSchedule/getSubscriptionSchedule', this.uid)

    // カード情報がない場合はお支払い情報のステータスが更新中、サブスクリプションとサブスクリプションスケジュールが存在しない場合は処理中なのでホームに遷移
    if (this.payment.status === 'updatingCard' || (!this.subscription && !this.subscriptionSchedule)) {
      this.$store.commit('setTelop', { show: true, msg: '現在、処理中です。\n時間をおいてから実行してください。', type: 'warning' })
      this.$router.push({ name: 'home' })
      return
    }

    // プラン情報がない場合は取得
    if (!this.plan) await this.$store.dispatch('plans/getPlan', this.uid)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} お支払い情報
     */
    payment () {
      return this.$store.getters['payments/payment']
    },
    /**
     * @return {Object} サブスクリプション情報
     */
    subscription () {
      return this.$store.getters['subscriptions/subscription']
    },
    /**
     * @return {Object} サブスクリプションスケジュール
     */
    subscriptionSchedule () {
      return this.$store.getters['subscriptionSchedule/subscriptionSchedule']
    },
    /**
     * @return {Object} 登録中のプラン情報
     */
    plan () {
      return this.$store.getters['plans/plan']
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";

.payment-plan {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
}
</style>
